import { BigNumber, ethers } from "ethers";
import { useEffect, useState } from "react";
import AssetWrapper from "../artifacts/AssetWrapper.json";
import { useConnection } from "../store/useConnection";

const RBTCAddr = ethers.constants.AddressZero;

interface AssetWrapperState {
  balance: BigNumber;
  withdraw: Function;
}

const initialState: AssetWrapperState = {
  balance: BigNumber.from(0),
  withdraw: () => {}
};

const withdraw = async (amount: BigNumber) => {
  const { provider, signer } = useConnection.getState();

  if (!provider || !signer) {
    throw new Error("No provider: Please connect your wallet");
  }

  const assetWrapperAddr = process.env.REACT_APP_WRAPPER_ADDRESS || "";

  const assetWrapper = new ethers.Contract(assetWrapperAddr, AssetWrapper.abi, provider);

  let gasLimit = await assetWrapper.connect(signer).estimateGas["withdraw(uint256)"](amount);
  gasLimit = gasLimit.add(BigNumber.from(2100));
  await (await assetWrapper.connect(signer)["withdraw(uint256)"](amount, { gasLimit, gasPrice: 1e9 })).wait();
};

export const useAssetWrapper = (): [AssetWrapperState] => {
  const [state, setState] = useState<AssetWrapperState>(initialState);
  const { provider, signer, address } = useConnection();

  const fetch = async (provider: ethers.providers.Provider, signer: ethers.Signer, signerAddress: string) => {
    const assetWrapperAddr = process.env.REACT_APP_WRAPPER_ADDRESS || "";

    const assetWrapper = new ethers.Contract(assetWrapperAddr, AssetWrapper.abi, provider);

    const balance = await assetWrapper.connect(signer).getBalance(signerAddress, RBTCAddr);

    setState({
      balance,
      withdraw
    });
  };

  useEffect(() => {
    if (provider && signer && address) {
      fetch(provider, signer, address);
    }
  }, [provider, signer, address]);

  return [state];
};
