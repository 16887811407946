import {
  Card,
  CardContent,
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow
} from "@mui/material";
import { SettingState } from "../../hooks/useSettings";
import { Subtitle } from "./styles";

interface GlobalSettingsProps {
  settings: SettingState;
}

export const GlobalSettings = ({ settings }: GlobalSettingsProps) => {

  const GeneralSettings = [
    {
      setting: "Allow change end date",
      isSet: settings.allowChangeEndDate,
      handle: settings.setAllowChangeEndDate,
    },
    {
      setting: "Allow eternal sales",
      isSet: settings.allowEternalSales,
      handle: settings.setAllowEternalSales,
    },
    {
      setting: "Allow deactivate listings",
      isSet: settings.allowDeactivateSales,
      handle: settings.setAllowDeactivateSale,
    },
  ];

  return (
    <Grid item md={12}>
      <Subtitle>General settings</Subtitle>
      <Card elevation={2}>
        <CardContent>
          <Table>
            <TableBody>
              {GeneralSettings.map((el) => (
                <TableRow key={el.setting}>
                  <TableCell>{el.setting}</TableCell>
                  <TableCell align="right">
                    <Switch
                      checked={el.isSet}
                      onClick={() => el.handle()}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </Grid>
  );
};
