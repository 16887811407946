import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import ReactDOM from "react-dom";
import React, { useEffect, useState } from "react";

const CustomSnackbar = ({
  type,
  message,
}: {
  type: AlertProps["severity"];
  message: string;
}) => {
  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return (
      <MuiAlert
        severity={type}
        elevation={6}
        ref={ref}
        variant="filled"
        {...props}
      />
    );
  });

  const [open, setOpen] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      setOpen(false);
    }, 5000);
  }, []);

  return (
    <Snackbar open={open}>
      <Alert>{message}</Alert>
    </Snackbar>
  );
};

export const TriggerSnackbar = (
  message: string,
  type: AlertProps["severity"] = "success"
) => {
  return ReactDOM.render(
    ReactDOM.createPortal(
      <CustomSnackbar type={type} message={message} />,
      document.body
    ),
    document.createElement("div")
  );
};
