import { Route, Routes } from "react-router-dom";
import { Home, Create, Details, Settings, Minter } from "./pages";

export const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/create" element={<Create />} />
      <Route path="/details/:listingId" element={<Details />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/minter" element={<Minter />} />
    </Routes>
  );
};
