import { gql, useQuery } from "@apollo/client";
import { Box, Grid, Typography } from "@mui/material";
import { BigNumber } from "ethers";
import { LoadingBackdrop } from "../shared/LoadingBackdrop";
import { ListingItem } from "./";

const LISTINGS = gql`
  query GetListings {
    listings(first: 30, orderBy: listingId, orderDirection: desc) {
      id
      listingId
    }
  }
`;

export const BrowseListings = () => {
  const { loading, data } = useQuery(LISTINGS);

  if (loading) {
    return <LoadingBackdrop open={true} />;
  }

  return (
    <Box sx={{ flexGrow: 1, marginTop: "20px" }} maxWidth={"lg"}>
      {data?.listings?.length ? (
        <>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{ marginBottom: "10px" }}
                color="text.disabled"
              >
                Current Listings
              </Typography>
            </Grid>
            {data.listings.map((listing: any) => (
              <Grid
                item
                xs={4}
                sx={{
                  padding: "10px",
                }}
                key={listing.listingId}
              >
                <ListingItem
                  listingId={BigNumber.from(listing.listingId)}
                  key={listing.listingId}
                />
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <Typography align={"center"} variant="h4" component="div">
          There are currently no listings in the marketplace.
        </Typography>
      )}
    </Box>
  );
};
