import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Box,
  DialogTitle,
  Dialog,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ethers } from "ethers";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useERC721 } from "../../hooks/useERC721";
import { useConnection } from "../../store/useConnection";
import { StrategyType, useListing } from "../../store/useListing";
import { TriggerSnackbar } from "../Snackbar";
import { RBTC } from "../../utils";

export const CreateListingForm = () => {
  const [selectedCurrency, setSelectedCurrency] = useState(
    ethers.constants.AddressZero
  );
  const [submitEnabled, setEnabledSubmit] = useState<boolean>(false);
  const [transactionSteps, setTransactionSteps] = useState<Array<string>>([]);
  const [strategy, setStrategy] = useState<StrategyType>(
    StrategyType.FixedPrice
  );
  const currentDate = new Date();
  const [endDateValue, setEndDateValue] = useState<Date | null>(currentDate);
  const minBidStepInput = useRef<HTMLInputElement>();
  const tokenIdInput = useRef<HTMLInputElement>();
  const nftAddressInput = useRef<HTMLInputElement>();
  const amountInput = useRef<HTMLInputElement>();

  const connectionState = useConnection();
  const listingContract = useListing();
  const ERC721 = useERC721();
  const navigate = useNavigate();

  const today = new Date();
  const inFiveDays = new Date();
  inFiveDays.setDate(today.getDate() + 10);

  let provider: ethers.providers.Provider | null;
  let signer: ethers.Signer | null;

  if (connectionState.isConnected) {
    provider = connectionState.provider;
    signer = connectionState.signer;
  }

  const onSubmit = async () => {
    const tokenId = Number(tokenIdInput.current?.value);
    const amount = Number(amountInput.current?.value);
    const endDate = endDateValue
      ? Math.floor(endDateValue.getTime() / 1000)
      : 0;
    const minBidStep = minBidStepInput
      ? Number(minBidStepInput.current?.value)
      : 0;
    const nftAddress = nftAddressInput.current?.value;

    if (
      provider &&
      signer &&
      tokenId &&
      amount &&
      nftAddress &&
      selectedCurrency
    ) {
      try {
        setTransactionSteps((state) => [...state, "Approving NFT transfer"]);
        await ERC721.approveToken(provider, nftAddress, tokenId, signer);
        setTransactionSteps((state) => [...state, "Listing NFT"]);
        await listingContract.createListing(
          provider,
          nftAddress,
          tokenId,
          selectedCurrency,
          amount,
          signer,
          strategy,
          endDate,
          minBidStep
        );
        TriggerSnackbar("Successfully created listing!", "success");
        navigate("/");
      } catch (error) {
        const err = error as Error;
        console.error("err", err);
        if (err.message) {
          TriggerSnackbar(err.message, "error");
        } else {
          TriggerSnackbar("Error listing NFT", "error");
        }
        setTransactionSteps([]);
      }
    } else {
      TriggerSnackbar(
        "Please connect a wallet and enter all the fields.",
        "error"
      );
    }
  };

  const checkAllFields = () => {
    const tokenId = Number(tokenIdInput.current?.value);
    const amount = Number(amountInput.current?.value);
    const nftAddress = nftAddressInput.current?.value;

    const enable = tokenId && amount && nftAddress;
    setEnabledSubmit(!!enable);
  };

  useEffect(() => {
    if (tokenIdInput.current) tokenIdInput.current.onkeyup = checkAllFields;
    if (amountInput.current) amountInput.current.onkeyup = checkAllFields;
    if (nftAddressInput.current) {
      nftAddressInput.current.onkeyup = checkAllFields;
    }
  }, [tokenIdInput, amountInput, nftAddressInput]);

  return (
    <Container maxWidth="sm" sx={{ marginTop: "50px" }}>
      <Typography
        variant="h5"
        sx={{
          marginBottom: "20px",
        }}
        color="text.primary"
      >
        Create Listing
      </Typography>
      <Card>
        <CardContent>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="start"
            spacing={2}
          >
            <TextField label="Token ID" fullWidth inputRef={tokenIdInput} />
            <TextField
              label="NFT Address"
              fullWidth
              inputRef={nftAddressInput}
            />

            <InputLabel>Type</InputLabel>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Button
                variant="outlined"
                style={{
                  background:
                    strategy === StrategyType.FixedPrice ? "#1976d2" : "none",
                  color:
                    strategy === StrategyType.FixedPrice ? "white" : "#1976d2",
                }}
                onClick={() => setStrategy(StrategyType.FixedPrice)}
              >
                Fixed Price Sale
              </Button>
              <Button
                variant="outlined"
                style={{
                  background:
                    strategy === StrategyType.EnglishAuction
                      ? "#1976d2"
                      : "none",
                  color:
                    strategy === StrategyType.EnglishAuction
                      ? "white"
                      : "#1976d2",
                }}
                onClick={() => setStrategy(StrategyType.EnglishAuction)}
              >
                English Auction
              </Button>
            </Stack>

            {strategy === StrategyType.EnglishAuction && (
              <Grid container spacing={5}>
                <Grid item md={12} sm={12} style={{ padding: 10 }}>
                  <InputLabel>Auction configuration</InputLabel>
                </Grid>
                <Grid item md={6}>
                  <TextField
                    type="number"
                    label="Minimum bid step"
                    fullWidth
                    inputRef={minBidStepInput}
                  />
                </Grid>
              </Grid>
            )}
            <InputLabel id="end-date-label">End date</InputLabel>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  label="Auction end date"
                  autoFocus
                  value={endDateValue}
                  minDate={today}
                  maxDate={inFiveDays}
                  onChange={(newValue: Date | null) =>
                    setEndDateValue(newValue)
                  }
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Stack>
            <InputLabel id="price-label">Price</InputLabel>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Select
                labelId="price-label"
                onChange={(e) => setSelectedCurrency(e.target.value)}
                value={selectedCurrency}
              >
                <MenuItem value={ethers.constants.AddressZero} key={RBTC}>
                  {RBTC}
                </MenuItem>

                <MenuItem value={process.env.REACT_APP_RIF_ADDRESS} key="tRIF">
                  tRIF
                </MenuItem>
              </Select>
              <TextField
                type="number"
                label="Amount"
                fullWidth
                inputRef={amountInput}
              />
            </Stack>
            <Button
              variant="contained"
              fullWidth
              onClick={onSubmit}
              disabled={!submitEnabled}
            >
              Submit
            </Button>
          </Stack>
        </CardContent>
      </Card>

      <Dialog
        open={transactionSteps.length > 0}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Transaction pending..."}
        </DialogTitle>
        <DialogContent>
          <Stack>
            {transactionSteps.map((v, i) => {
              return (
                <Box
                  key={i}
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    flexWrap: "nowrap",
                  }}
                >
                  {i === transactionSteps.length - 1 && (
                    <CircularProgress size={20} />
                  )}
                  {i !== transactionSteps.length - 1 && "✓"}
                  <label style={{ marginLeft: "10px" }}>{v}</label>
                </Box>
              );
            })}
          </Stack>
        </DialogContent>
      </Dialog>
    </Container>
  );
};
