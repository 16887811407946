import { gql, useQuery } from "@apollo/client";
import {
  Card,
  CardContent,
  Container,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { BigNumber, ethers } from "ethers";
import { useEffect, useState } from "react";
import { Bid, useListingHighestBidder } from "../../hooks/useListingBids";
import { formatAddress } from "../../utils";

interface ListingBidHistoryProps {
  listingId: BigNumber;
}

export const ListingBidHistory = ({ listingId }: ListingBidHistoryProps) => {
  const LISTING = gql`
    query MyQuery {
      listings(where: { listingId: "${listingId.toString()}" }) {
        auctions {
          Bids {
            bidder
            price
          }
        }
      }
    }
  `;

  const [highestBid] = useListingHighestBidder(listingId);
  const [bids, setBids] = useState<Array<Bid>>([]);
  const { data } = useQuery(LISTING);

  useEffect(() => {
    if (data && data.listings && data.listings.length === 0) return;

    if (
      data &&
      data.listings &&
      data.listings.length > 0 &&
      data.listings[0].auctions &&
      data.listings[0].auctions.length > 0 &&
      data.listings[0].auctions[0].Bids &&
      data.listings[0].auctions[0].Bids.length > 0
    ) {
      const bidsHistory: Array<Bid> = data.listings[0].auctions[0].Bids.map(
        (i: { bidder: string; price: string }) => ({
          from: i.bidder,
          amount: i.price,
        })
      );

      setBids([...bidsHistory]);
    } else {
      setBids([highestBid.bidder]);
    }
  }, [data, highestBid.bidder]);

  if (bids.length === 0) return null;

  return (
    <Container sx={{ marginTop: "35px" }}>
      <Typography>History</Typography>
      <Card>
        <CardContent>
          <Table size="small">
            <TableBody>
              {bids.map((bid) => (
                <TableRow key={bid.from}>
                  <TableCell>{formatAddress(bid.from, 15)}</TableCell>
                  <TableCell>
                    {ethers.utils.formatEther(bid.amount.toString()).toString()}{" "}
                    RBTC
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </Container>
  );
};
