import { Button } from "@mui/material";
import { BigNumber, ethers } from "ethers";
import { TriggerModal } from "../../Modal";
import { TriggerSnackbar } from "../../Snackbar";
import { StrategyType, useListing } from "../../../store/useListing";

interface BuyButtonProps {
  listingId: BigNumber;
  price: BigNumber;
  priceCurrencyAddress: string;
  priceSymbol: string;
  strategyType: StrategyType;
}

export const BuyButton = ({
  listingId,
  price,
  priceCurrencyAddress,
  priceSymbol,
  strategyType,
}: BuyButtonProps) => {
  const { placeBid } = useListing();

  const strategyText =
    strategyType === StrategyType.EnglishAuction ? "Purchase" : "Place bid for";

  const ModalContent = () => {
    return (
      <>
        Are you sure you want to {strategyText} this listing #
        {listingId.toNumber()} by {ethers.utils.formatEther(price)}{" "}
        {priceSymbol}?
      </>
    );
  };

  const onConfirmationHandler = async () => {
    try {
      await placeBid(listingId, price, priceCurrencyAddress);
      TriggerSnackbar(`${strategyText} Successful`, "success");
    } catch (error) {
      const err = error as Error;
      console.log(err);
      TriggerSnackbar(err.message, "error");
    }
    return true;
  };

  const onClickHandler = () => {
    TriggerModal(
      `${strategyText} listing`,
      <ModalContent />,
      onConfirmationHandler
    );
  };

  return (
    <Button onClick={onClickHandler} size="small" variant="contained">
      {strategyType === StrategyType.EnglishAuction ? "Place bid" : "Buy"}
    </Button>
  );
};
