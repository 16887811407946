import ReactDOM from 'react-dom';
import { useState } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { createRoot } from 'react-dom/client';

const CustomModal = ({
  title,
  content,
  onHandleClose,
  onHandleConfirmation
}: {
  title: String;
  content: JSX.Element;
  onHandleClose: Function;
  onHandleConfirmation: Function;
}) => {
  const [open, setOpen] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onClose = () => {
    onHandleClose();
    setOpen(false);
  };

  const onConfirm = async () => {
    try {
      setIsLoading(true);
      if (!(await onHandleConfirmation())) {
        setIsLoading(false);
        return;
      }
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={onClose} aria-describedby="alert-dialog-slide-description">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContent id="alert-dialog-slide-description">{content}</DialogContent>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isLoading}>
          Cancel
        </Button>
        <Button variant={'outlined'} onClick={onConfirm} disabled={isLoading}>
          Submit
          {isLoading && <CircularProgress sx={{ marginLeft: 2 }} size={20} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const TriggerModal = (
  title: String,
  content: JSX.Element,
  onHandleConfirmation: Function,
  onHandleClose: Function = () => {}
) => {
  const root = createRoot(document.createElement('div'));
  return root.render(
    ReactDOM.createPortal(
      <CustomModal
        title={title}
        content={content}
        onHandleClose={onHandleClose}
        onHandleConfirmation={onHandleConfirmation}
      />,
      document.body
    )
  );
};
