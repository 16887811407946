import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { Container } from "@mui/system";
import { BrowserRouter } from "react-router-dom";
import { Footer, Header } from "./pages/_layout";
import { Router } from "./Router";
import "./utils/ClassnamesGenerator";

const indexerURL = process.env.REACT_APP_GRAPHQL_URL || "";

const client = new ApolloClient({
  uri: indexerURL,
  cache: new InMemoryCache(),
});

function App() {
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Header />
        <Container
          sx={{
            minHeight: "50vh",
          }}
        >
          <Router />
        </Container>
        <Footer />
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
