import { ethers } from "ethers";
import { rLoginConnector } from "../utils/connector.utils";
import create from "zustand";

export interface IConnection {
  provider: ethers.providers.Provider | null;
  signer: ethers.Signer | null;
  address: string;
  connect: Function;
  disconnect: Function | null;
  isConnected: Boolean;
  checkConnection: Function;
}

export const useConnection = create<IConnection>((set, get) => ({
  address: "",
  provider: null,
  signer: null,
  disconnect: null,
  isConnected: false,
  connect: async (provider: ethers.providers.Web3Provider | null) => {
    let _provider: ethers.providers.Web3Provider;

    if (!provider) {
      const connection = await rLoginConnector.connect();
      _provider = new ethers.providers.Web3Provider(connection.provider);
    } else {
      _provider = provider;
    }

    const _address = await _provider!.getSigner(0).getAddress();
    const _signer = await _provider!.getSigner(0);

    set((_state) => ({
      provider: _provider,
      address: _address,
      signer: _signer,
      isConnected: true,
    }));

    _provider.on("accountsChanged", async () => {
      const _address = await _provider!.getSigner(0).getAddress();
      const _signer = await _provider!.getSigner(0);
      set((_state) => ({
        address: _address,
        signer: _signer,
      }));
    });
  },
  checkConnection: async () => {
    if (typeof window.ethereum !== "undefined") {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const accounts = await provider.listAccounts();

      if (accounts.length > 0) get().connect(provider);
    }
  },
}));
