import {
  Card,
  CardContent,
  CardMedia,
  Chip,
  CircularProgress,
  Typography,
} from "@mui/material";
import { BigNumber, ethers } from "ethers";
import { useNavigate } from "react-router-dom";
import {
  AuctionState,
  useListingMetadata,
} from "../../hooks/useListingMetadata";

interface ListingProps {
  listingId: BigNumber;
}

export const ListingItem = ({ listingId }: ListingProps) => {
  const [listingMetadata, loading] = useListingMetadata(listingId);
  const navigate = useNavigate();

  const navigateHandler = () => {
    navigate(`/details/${listingId}`);
  };

  if (listingMetadata?.owner === ethers.constants.AddressZero) {
    return (
      <Card
        sx={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        elevation={1}
        onClick={navigateHandler}
      >
        <CardContent>
          <>Listing #{listingId.toNumber()} exited the marketplace</>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card
      sx={{ position: "relative", height: "100%", cursor: "pointer" }}
      elevation={1}
      onClick={navigateHandler}
    >
      {loading && <CircularProgress sx={{ margin: 5 }} />}
      {!loading && listingMetadata?.tokenURI && (
        <CardMedia
          component="img"
          alt="Listing image"
          height="140"
          sx={{
            objectFit: "contain",
          }}
          image={listingMetadata?.tokenURI}
        />
      )}
      {listingMetadata?.state === AuctionState.Initial && (
        <Chip
          label="Sold"
          size="small"
          sx={{ marginLeft: 2, position: "absolute", top: 10, right: 10 }}
        />
      )}
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          Listing #{listingId.toNumber()}
        </Typography>
        <Typography gutterBottom variant="body2" component="div">
          <>
            Price: {ethers.utils.formatEther(listingMetadata?.price || "0")}{" "}
            {listingMetadata?.priceCurrencySymbol}
          </>
        </Typography>
      </CardContent>
    </Card>
  );
};
