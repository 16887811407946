import { ethers } from "ethers";
import { useEffect, useState } from "react";
import marketplaceConfigurationJson from "../artifacts/MarketplaceConfiguration.json";
import { TriggerSnackbar } from "../components/Snackbar";
import { useConnection } from "../store/useConnection";

export interface SettingState {
  allowChangeEndDate: boolean;
  setAllowChangeEndDate: Function;

  allowEternalSales: boolean;
  setAllowEternalSales: Function;

  allowDeactivateSales: boolean;
  setAllowDeactivateSale: Function;

  marketplaceFeesPayee: string;
  setMarketplaceFeesPayee: Function;
}

const intialState: SettingState = {
  allowChangeEndDate: false,
  setAllowChangeEndDate: () => {},

  allowEternalSales: false,
  setAllowEternalSales: () => {},

  allowDeactivateSales: false,
  setAllowDeactivateSale: () => {},

  marketplaceFeesPayee: "",
  setMarketplaceFeesPayee: () => {}
};

export const useSettings = (): [SettingState, boolean] => {
  const [settingState, setSettingState] = useState<SettingState>(intialState);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { provider, signer } = useConnection();

  const executeConfigFunction = (contract: ethers.Contract, fn: string, value: any) => {
    return async () => {
      setIsLoading(true);
      try {
        let tx = await contract[fn](value);
        await tx.wait();
        TriggerSnackbar("Successfully updated setting", "success");
        fetchSettings(contract.provider);
      } catch (err) {
        TriggerSnackbar((err as Error).message, "error");
        console.log((err as Error).message);
      }
      setIsLoading(false);
    };
  };

  const fetchSettings = async (provider: ethers.providers.Provider) => {
    const marketplaceConfigAddr = process.env.REACT_APP_MKP_CONF_ADDRESS || "";

    const configurationContract = new ethers.Contract(
      marketplaceConfigAddr,
      marketplaceConfigurationJson.abi,
      provider
    );

    const allowChangeEndDate = (await configurationContract.isEndDateChangeAllowed()) as boolean;
    const allowEternalSales = (await configurationContract.isEternalSalesAllowed()) as boolean;
    const allowDeactivateSale = (await configurationContract.isDeactivateSaleSupported()) as boolean;
    const marketplaceFeesPayee = (await configurationContract.getMarketplaceFeesPayee()) as string;

    setSettingState({
      allowChangeEndDate,
      setAllowChangeEndDate: executeConfigFunction(
        configurationContract.connect(signer!),
        "setAllowEndDateChange",
        !allowChangeEndDate
      ),
      allowEternalSales,
      setAllowEternalSales: executeConfigFunction(
        configurationContract.connect(signer!),
        "setAllowEternalSales",
        !allowEternalSales
      ),
      allowDeactivateSales: allowDeactivateSale,
      setAllowDeactivateSale: executeConfigFunction(
        configurationContract.connect(signer!),
        "setSupportDeactivateSale",
        !allowDeactivateSale
      ),
      marketplaceFeesPayee,
      setMarketplaceFeesPayee: executeConfigFunction(
        configurationContract.connect(signer!),
        "setMarketplaceFeesPayee",
        marketplaceFeesPayee
      )
    });
  };

  useEffect(() => {
    if (provider) {
      (async () => {
        setIsLoading(true);
        await fetchSettings(provider);
        setIsLoading(false);
      }
      )();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider]);

  return [settingState, isLoading];
};
