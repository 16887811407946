import {
  Alert,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { BigNumber } from "ethers";
import { useEffect, useRef, useState } from "react";
import { useSimpleNFT } from "../../hooks/useSimpleNFT";
import { getImageFromSource } from "../../utils";
import { TriggerModal } from "../Modal";
import { TriggerSnackbar } from "../Snackbar";

export const NFTMinter = () => {
  const { mintNFT } = useSimpleNFT();
  const imageInput = useRef<HTMLInputElement>();
  const [submitEnabled, setSubmitEnabled] = useState<boolean>(false);
  const [result, setResult] = useState<{
    tokenId: BigNumber;
    contractAddress: string;
  }>({ tokenId: BigNumber.from(0), contractAddress: "" });

  const ModalContent = ({ imageURL }: { imageURL: string }) => (
    <>
      <p>Mint NFT using the following image? </p>
      <CardMedia
        component="img"
        alt="Listing image"
        height="140"
        sx={{
          objectFit: "contain",
        }}
        image={imageURL}
      ></CardMedia>
    </>
  );

  const onSubmit = async () => {
    let imageURL = await getImageFromSource(imageInput.current?.value || "");

    TriggerModal(
      "Mint NFT",
      <ModalContent imageURL={imageURL} />,
      onHandleConfirmation
    );
  };

  const onHandleConfirmation = async () => {
    if (imageInput.current) {
      try {
        const res = await mintNFT(imageInput.current.value);
        setResult(res);
        TriggerSnackbar("Successfully minted new NFT", "success");
        return true;
      } catch (e) {
        TriggerSnackbar(
          "There was an error while minting the NFT, try again later.",
          "error"
        );
        return false;
      }
    }
  };

  const validateImage = () => {
    if (imageInput.current) {
      setSubmitEnabled(imageInput.current.value !== "");
    }
  };

  useEffect(() => {
    if (imageInput.current) imageInput.current.onkeyup = validateImage;
  }, [imageInput]);

  return (
    <Container maxWidth="sm" sx={{ marginTop: "50px" }}>
      <Typography
        variant="h5"
        sx={{
          marginBottom: "20px",
        }}
        color="text.primary"
      >
        Mint your own NFT
      </Typography>
      <Card>
        <CardContent>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="start"
            spacing={2}
          >
            <Alert severity="info">
              If a JSON file is provided make sure that it contains an "image"
              property.  Ex: ("image": "https://picsum.photos/200")
            </Alert>
            <TextField
              label="NFT image or JSON file URL"
              fullWidth
              inputRef={imageInput}
            />
            <Button
              variant="contained"
              fullWidth
              onClick={onSubmit}
              disabled={!submitEnabled}
            >
              Submit
            </Button>
          </Stack>
        </CardContent>
      </Card>
      {result.contractAddress !== "" && (
        <Card sx={{ marginTop: "20px" }}>
          <CardContent>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    NFT Contract Address: {result.contractAddress}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Token ID: {result.tokenId.toNumber()}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      )}
    </Container>
  );
};
