import {
  AuctionState,
  ListMetadataState,
} from "../../hooks/useListingMetadata";
import { BuyButton } from "./actions/BuyButton";
import { ExitMarketButton } from "./actions/ExitMarketButton";
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { ethers } from "ethers";
import { ActivationButton } from "./actions/ActivationButton";
import { useConnection } from "../../store/useConnection";
import { ChangePriceButton } from "./actions/ChangePriceButton";
import { ChangeEndDateButton } from "./actions/ChangeEndDateButton";
import { EndSaleButton } from "./actions/EndSaleButton";
import { StrategyType } from "../../store/useListing";

interface ListingActionsProps {
  listingMetadata: ListMetadataState;
}

export const ListingActions = ({ listingMetadata }: ListingActionsProps) => {
  const { address } = useConnection();
  const saleState = (state: AuctionState) => {
    return state === AuctionState.Initial ? "Sold for" : "Price";
  };

  const listingType = (strategy: StrategyType) => {
    return strategy === StrategyType.FixedPrice
      ? "Fixed price sale"
      : "English auction";
  };

  return (
    <Grid container>
      <Grid item md={12}>
        <Card elevation={0}>
          <CardContent>
            <Typography gutterBottom variant="body2" component="div">
              {saleState(listingMetadata.state)}
            </Typography>
            <Typography gutterBottom variant="h5" component="div">
              {ethers.utils.formatEther(listingMetadata.price)}{" "}
              {listingMetadata.priceCurrencySymbol}
            </Typography>
            <Typography gutterBottom variant="body2" component="div">
              Type: {listingType(listingMetadata.strategyType)}
            </Typography>
          </CardContent>
          <CardActions>
            {listingMetadata.state === AuctionState.Active && (
              <BuyButton
                listingId={listingMetadata.listingId}
                price={listingMetadata.price}
                priceCurrencyAddress={listingMetadata.priceCurrencyAddress}
                priceSymbol={listingMetadata.priceCurrencySymbol}
                strategyType={listingMetadata.strategyType}
              />
            )}
            {listingMetadata.owner === address &&
              listingMetadata.state === AuctionState.Initial && (
                <ExitMarketButton
                  listingId={listingMetadata.listingId}
                  tokenId={listingMetadata.tokenId}
                  exitFee={listingMetadata.exitFee}
                  exitFeeCurrencySymbol={listingMetadata.exitFeeCurrencySymbol}
                />
              )}
            {listingMetadata.owner === address &&
              (listingMetadata.state === AuctionState.Active ||
                listingMetadata.state === AuctionState.Inactive) && (
                <>
                  <ActivationButton
                    listingId={listingMetadata.listingId}
                    state={listingMetadata.state}
                  />
                  {!listingMetadata.hasBids && (
                    <ChangePriceButton
                      listingId={listingMetadata.listingId}
                      price={listingMetadata.price}
                      priceCurrencyAddress={
                        listingMetadata.priceCurrencyAddress
                      }
                      priceCurrencySymbol={listingMetadata.priceCurrencySymbol}
                      minBidStep={listingMetadata.minBidStep}
                      strategyType={listingMetadata.strategyType}
                    />
                  )}
                  <ChangeEndDateButton
                    listingId={listingMetadata.listingId}
                    currentEndDate={listingMetadata.endDate}
                  />
                  <EndSaleButton listingId={listingMetadata.listingId} />
                </>
              )}
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};
