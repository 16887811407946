import { Typography } from "@mui/material";
import { styled } from "@mui/system";

export const Subtitle = styled(Typography)({
  fontSize: "14px",
  color: "#5f5f5f",
  marginBottom: "5px",
  marginTop: "15px",
});

export const Title = styled(Typography)({
  fontSize: "20px",
  color: "black",
  marginBottom: "5px",
});
