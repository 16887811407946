import { ethers } from "ethers";
import create from "zustand";
import ERC721 from "../artifacts/ERC721.json";

interface ERC721State {
  approveToken: Function;
}

export const useERC721 = create<ERC721State>((set, get) => ({
  approveToken: async (
    provider: any,
    nftAddress: string,
    tokenId: number,
    signer: ethers.Signer
  ) => {
    const to = process.env.REACT_APP_WRAPPER_ADDRESS || "";
    const nft = new ethers.Contract(nftAddress, ERC721.abi, provider);

    const owner = await nft.connect(signer).ownerOf(tokenId);
    if (owner !== (await signer.getAddress())) {
      throw new Error("User is not the owner of the NFT");
    }

    const approvedAddress = await nft.connect(signer).getApproved(tokenId);
    if (approvedAddress === to) return;

    const txApprove = await nft.connect(signer).approve(to, tokenId);
    const receiptApprove = await txApprove.wait();
    return receiptApprove;
  },
}));
