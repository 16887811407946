import { BigNumber, ethers } from "ethers";
import { useEffect, useState } from "react";
import FixedPriceJson from "../artifacts/FixedPriceSaleStrategy.json";
import ListingJson from "../artifacts/ListingManager.json";
import EnglishAuctionJson from "../artifacts/EnglishAuctionStrategy.json";
import { useConnection } from "../store/useConnection";
import { StrategyType } from "../store/useListing";

export enum AuctionState {
  Initial = 0,
  Created = 1,
  Active = 2,
  Inactive = 3,
  NA = 4,
}

export interface Royalties {
  marketplace: BigNumber;
  sponsor: BigNumber;
  author: BigNumber;
}

export interface Bid {
  from: string;
  amount: BigNumber;
}

export interface ListHigherBidderState {
  bidder: Bid;
}

const intialState: ListHigherBidderState = {
  bidder: {
    from: "",
    amount: BigNumber.from(0),
  },
};

export const useListingHighestBidder = (listingId: BigNumber) => {
  const [state, setState] = useState<ListHigherBidderState>(intialState);
  const FixedPriceAddr = process.env.REACT_APP_FIXED_ADDRESS || "";
  const EnglishAuctionAddr = process.env.REACT_APP_ENGLISH_ADDRESS || "";
  const ListingAddr = process.env.REACT_APP_LISTING_ADDRESS || "";
  const { provider } = useConnection();

  const fetchHighestBidder = async (listingId: BigNumber) => {
    const fixedPrice = new ethers.Contract(
      FixedPriceAddr,
      FixedPriceJson.abi,
      provider!
    );

    const englishAuction = new ethers.Contract(
      EnglishAuctionAddr,
      EnglishAuctionJson.abi,
      provider!
    );

    const listingMgr = new ethers.Contract(
      ListingAddr,
      ListingJson.abi,
      provider!
    );

    const auctionItemRaw = await listingMgr.getListing(listingId);

    const strategy =
      auctionItemRaw.strategy === process.env.REACT_APP_FIXED_ADDRESS
        ? StrategyType.FixedPrice
        : StrategyType.EnglishAuction;

    const [saleStrategyRaw] = await Promise.all([
      strategy === StrategyType.FixedPrice
        ? fixedPrice.getAuctionItem(listingMgr.address, listingId)
        : englishAuction.getAuctionItem(listingMgr.address, listingId),
    ]);

    setState({
      bidder: {
        from: saleStrategyRaw.latestBid.bidder,
        amount: saleStrategyRaw.latestBid.bid,
      },
    });
  };

  useEffect(() => {
    if (provider) {
      fetchHighestBidder(listingId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingId, provider]);

  return [state];
};
