import { ArrowBack } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import { BigNumber } from "ethers";
import { useNavigate, useParams } from "react-router-dom";
import { ListingActions } from "../components/listing/ListingActions";
import { ListingBidHistory } from "../components/listing/ListingBidHistory";
import { ListingData } from "../components/listing/ListingData";
import { ListingEndDate } from "../components/listing/ListingEndDate";
import { AuctionState, useListingMetadata } from "../hooks/useListingMetadata";

export const Details = () => {
  const navigate = useNavigate();
  const { listingId } = useParams();
  const [listingMetadata] = useListingMetadata(BigNumber.from(listingId));

  return (
    <Grid container spacing={2} sx={{ marginTop: 5 }}>
      <Grid item md={1} />
      <Grid item md={11}>
        <Button
          size="small"
          startIcon={<ArrowBack />}
          onClick={() => {
            navigate("/");
          }}
        >
          Go back to browse listings
        </Button>
      </Grid>
      <Grid item md={1} />
      <Grid item md={4}>
        <ListingData
          listingId={listingMetadata.listingId}
          tokenURI={listingMetadata.tokenURI}
          author={listingMetadata.author}
          owner={listingMetadata.owner}
          tokenId={listingMetadata.underlyingTokenId}
          nftContract={listingMetadata.nftContract}
          royalties={listingMetadata.royalties}
          exitFee={listingMetadata.exitFee}
          exitFeeCurrencySymbol={listingMetadata.exitFeeCurrencySymbol}
          state={listingMetadata.state}
        />
      </Grid>
      <Grid item md={6} sx={{ marginLeft: "5px" }}>
        {listingMetadata.endDate.gt(0) &&
          listingMetadata.state !== AuctionState.Initial && (
            <ListingEndDate endDate={listingMetadata.endDate} />
          )}
        <ListingActions listingMetadata={listingMetadata} />
        <ListingBidHistory listingId={listingMetadata.listingId} />
      </Grid>
      <Grid item md={1} />
    </Grid>
  );
};
