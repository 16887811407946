import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { MarketplaceTheme } from "./utils/GlobalStyles";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <MarketplaceTheme>
      <App />
    </MarketplaceTheme>
  </React.StrictMode>
);
