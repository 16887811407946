import { Button, Stack, TextField } from "@mui/material";
import { BigNumber, ethers } from "ethers";
import { useRef, useState } from "react";
import { StrategyType, useListing } from "../../../store/useListing";
import { TriggerModal } from "../../Modal";
import { TriggerSnackbar } from "../../Snackbar";

interface ChangePriceButtonProps {
  listingId: BigNumber;
  price: BigNumber;
  priceCurrencyAddress: string;
  priceCurrencySymbol: string;
  minBidStep: BigNumber;
  strategyType: StrategyType;
}

export const ChangePriceButton = ({
  listingId,
  price,
  priceCurrencyAddress,
  priceCurrencySymbol,
  minBidStep,
  strategyType,
}: ChangePriceButtonProps) => {
  const { changePrice } = useListing();
  const priceFieldElement = useRef<HTMLInputElement>();
  const minBidStepElement = useRef<HTMLInputElement>();

  const ModalContent = () => {
    const [priceError, setPriceError] = useState<string>("");
    const [minBidError, setMinBidError] = useState<string>("");

    const onChangePrice = () => {
      if (priceFieldElement.current?.value) {
        setPriceError("");
      } else {
        setPriceError("Must set a price");
      }
    };

    const onChangeMinBidStep = () => {
      if (priceFieldElement.current?.value) {
        setMinBidError("");
      } else {
        setMinBidError("Must set a price");
      }
    };

    return (
      <>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="start"
          spacing={2}
        >
          <TextField
            inputRef={priceFieldElement}
            type="number"
            label={`Price (${priceCurrencySymbol})`}
            defaultValue={ethers.utils.formatEther(price.toString())}
            onChange={onChangePrice}
          />

          {priceError && (
            <p
              style={{
                color: "red",
                padding: "0",
                margin: "0",
              }}
            >
              {priceError}
            </p>
          )}
          {strategyType === StrategyType.EnglishAuction && (
            <TextField
              inputRef={minBidStepElement}
              type="number"
              label={`Min bid step (${priceCurrencySymbol})`}
              defaultValue={ethers.utils.formatEther(minBidStep.toString())}
              onChange={onChangeMinBidStep}
            />
          )}
          {minBidError && (
            <p
              style={{
                color: "red",
                padding: "0",
                margin: "0",
              }}
            >
              {minBidError}
            </p>
          )}
        </Stack>
      </>
    );
  };

  const onConfirmationHandler = async () => {
    try {
      if (!priceFieldElement.current) return;
      if (
        strategyType === StrategyType.EnglishAuction &&
        !minBidStepElement.current
      )
        return;
      if (priceFieldElement.current!.value !== "") {
        const priceWOFormat = ethers.utils.parseEther(
          priceFieldElement.current.value
        );
        const minBidStepFormat = ethers.utils.parseEther(
          minBidStepElement.current?.value || "0"
        );
        await changePrice(
          listingId,
          strategyType,
          priceWOFormat,
          priceCurrencyAddress,
          minBidStepFormat
        );
        TriggerSnackbar("Price updated successfully", "success");
      }
    } catch (error) {
      const err = error as Error;
      console.log(err);
      TriggerSnackbar(err.message, "error");
    }
    return true;
  };

  const onClickHandler = () => {
    TriggerModal("Change price", <ModalContent />, onConfirmationHandler);
  };

  return (
    <Button onClick={onClickHandler} size="small" variant="outlined">
      Change price
    </Button>
  );
};
