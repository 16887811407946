import {
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow
} from "@mui/material";
import { Subtitle } from "./styles";

interface MarketplaceSettingsProps {
  marketplaceFeesPayee: string;
}

export const MarketplaceSettings = ({ marketplaceFeesPayee }: MarketplaceSettingsProps) => {
  const Settings = [
    {
      label: "Marketplace fees payee",
      value: marketplaceFeesPayee
    }
  ];

  return (
    <Grid item md={12}>
      <Subtitle>Marketplace settings</Subtitle>
      <Card elevation={2}>
        <CardContent>
          <Table>
            <TableBody>
              {Settings.map(el => (
                <TableRow key={el.label}>
                  <TableCell>{el.label}</TableCell>
                  <TableCell align="right">{el.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </Grid>
  );
};
