import RLogin from "@rsksmart/rlogin";

const rpcUrls = {
  30: "https://public-node.rsk.co",
  31: "http://rskj-01.testnet.rif-marketplace.iovlabs.net:4444",
  33: "http://161.35.190.64:4444",
  1337: "http://localhost:4444",
};

const supportedChains = Object.keys(rpcUrls).map(Number);

const infoOptions = {
  30: { addressBaseURL: "https://explorer.rsk.co/address/" },
  31: {
    addressBaseURL: "http://rskj-01.testnet.rif-marketplace.iovlabs.net:4444",
  },
};

export const rLoginConnector = new RLogin({
  rpcUrls,
  supportedChains,
  infoOptions,
});
