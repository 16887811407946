import { Button } from "@mui/material";
import { BigNumber, ethers } from "ethers";
import { TriggerModal } from "../../Modal";
import { TriggerSnackbar } from "../../Snackbar";
import { useListing } from "../../../store/useListing";

interface ExitMarketButtonProps {
  listingId: BigNumber;
  tokenId: BigNumber;
  exitFee: BigNumber;
  exitFeeCurrencySymbol: string;
}

export const ExitMarketButton = ({
  listingId,
  tokenId,
  exitFee,
  exitFeeCurrencySymbol,
}: ExitMarketButtonProps) => {
  const { exitMarket } = useListing();

  const ModalContent = () => {
    return (
      <>
        Are you sure you want to take listing #{listingId.toNumber()} out of the
        marketplace? You must pay {ethers.utils.formatEther(exitFee)}{" "}
        {exitFeeCurrencySymbol} as exit fee.
      </>
    );
  };

  const onConfirmationHandler = async () => {
    try {
      await exitMarket(tokenId, exitFee);
      TriggerSnackbar("Successful exit market", "success");
    } catch (error) {
      const err = error as Error;
      console.log(err);
      TriggerSnackbar(err.message, "error");
    }
    return true;
  };

  const onClickHandler = () => {
    TriggerModal(
      "Listing exit market",
      <ModalContent />,
      onConfirmationHandler
    );
  };

  return (
    <Button onClick={onClickHandler} size="small" variant="outlined">
      Claim
    </Button>
  );
};
