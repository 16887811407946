import { BigNumber, ethers } from "ethers";
import SimpleNFTJSON from "../artifacts/SimpleNFT.json";
import { useConnection } from "../store/useConnection";

interface SimpleNFTActions {
  mintNFT(
    imageURL: string
  ): Promise<{ tokenId: BigNumber; contractAddress: string }>;
}

const NFTAddr = process.env.REACT_APP_NFT_ADDRESS || "";

export const useSimpleNFT = (): SimpleNFTActions => {
  return {
    mintNFT: async (imageURL: string) => {
      const { signer, address } = useConnection.getState();
      const nftContractFactory = new ethers.ContractFactory(
        SimpleNFTJSON.abi,
        SimpleNFTJSON.bytecode,
        signer!
      );

      let tx = await nftContractFactory
        .attach(NFTAddr)
        .connect(signer!)
        .mintNFT(address, imageURL);

      let r = await tx.wait();

      const tokenId = r.events[0].args.tokenId;

      return {
        tokenId,
        contractAddress: NFTAddr,
      };
    },
  };
};
