import { Button } from "@mui/material";
import { BigNumber } from "ethers";
import { useListing } from "../../../store/useListing";
import { TriggerModal } from "../../Modal";
import { TriggerSnackbar } from "../../Snackbar";

interface EndSaleButtonProps {
  listingId: BigNumber;
}

export const EndSaleButton = ({ listingId }: EndSaleButtonProps) => {
  const { endSale } = useListing();

  const ModalContent = () => {
    return <>Are you sure you want to end this sale: {listingId.toNumber()}?</>;
  };

  const onConfirmationHandler = async () => {
    try {
      await endSale(listingId);
      TriggerSnackbar("Successful end sale", "success");
    } catch (error) {
      const err = error as Error;
      console.log(err);
      TriggerSnackbar(err.message, "error");
    }
    return true;
  };

  const onClickHandler = () => {
    TriggerModal("End sale", <ModalContent />, onConfirmationHandler);
  };

  return (
    <Button onClick={onClickHandler} size="small" variant="outlined">
      End sale
    </Button>
  );
};
