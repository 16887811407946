import { BigNumber, ethers, constants } from "ethers";
import { useCallback, useEffect, useState } from "react";
import AssetWrapperJson from "../artifacts/AssetWrapper.json";
import FixedPriceJson from "../artifacts/FixedPriceSaleStrategy.json";
import ListingJson from "../artifacts/ListingManager.json";
import ERC721Json from "../artifacts/ERC721.json";
import erc20MockJson from "../artifacts/ERC20Mock.json";
import EnglishAuctionJson from "../artifacts/EnglishAuctionStrategy.json";
import { useConnection } from "../store/useConnection";
import { getImageFromSource, RBTC } from "../utils";
import { StrategyType } from "../store/useListing";

export enum AuctionState {
  Initial = 0,
  Created = 1,
  Active = 2,
  Inactive = 3,
  NA = 4,
}

export interface Royalties {
  marketplace: BigNumber;
  sponsor: BigNumber;
  author: BigNumber;
}

export interface ListMetadataState {
  price: BigNumber;
  priceCurrencyAddress: string;
  priceCurrencySymbol: string;
  minBidStep: BigNumber;
  listingId: BigNumber;
  tokenURI: string;
  tokenId: BigNumber;
  underlyingTokenId: BigNumber;
  endDate: BigNumber;
  state: AuctionState;
  nftContract: string;
  owner: string;
  exitFee: BigNumber;
  exitFeeCurrencyAddress: string;
  exitFeeCurrencySymbol: string;
  author: string;
  hasBids: boolean;
  royalties: Royalties;
  strategyType: StrategyType;
}

const intialState: ListMetadataState = {
  price: ethers.constants.Zero,
  priceCurrencyAddress: "",
  priceCurrencySymbol: "",
  minBidStep: ethers.constants.Zero,
  state: AuctionState.NA,
  listingId: ethers.constants.Zero,
  tokenURI: "",
  tokenId: ethers.constants.Zero,
  underlyingTokenId: ethers.constants.Zero,
  endDate: ethers.constants.Zero,
  nftContract: "",
  owner: "",
  exitFee: ethers.constants.Zero,
  exitFeeCurrencyAddress: "",
  exitFeeCurrencySymbol: "",
  author: "",
  hasBids: false,
  royalties: {
    marketplace: ethers.constants.Zero,
    author: ethers.constants.Zero,
    sponsor: ethers.constants.Zero,
  },
  strategyType: StrategyType.FixedPrice,
};

export const useListingMetadata = (
  listingId: BigNumber
): [ListMetadataState, boolean] => {
  const [state, setState] = useState<ListMetadataState>(intialState);
  const [loading, setLoading] = useState<boolean>(false);
  const AssetWrapperAddr = process.env.REACT_APP_WRAPPER_ADDRESS || "";
  const FixedPriceAddr = process.env.REACT_APP_FIXED_ADDRESS || "";
  const EnglishAuctionAddr = process.env.REACT_APP_ENGLISH_ADDRESS || "";
  const ListingAddr = process.env.REACT_APP_LISTING_ADDRESS || "";
  const { provider } = useConnection();

  const fetchMetadata = useCallback(
    async (listingId: BigNumber) => {
      const assetWrapper = new ethers.Contract(
        AssetWrapperAddr,
        AssetWrapperJson.abi,
        provider!
      );

      const fixedPrice = new ethers.Contract(
        FixedPriceAddr,
        FixedPriceJson.abi,
        provider!
      );

      const englishAuction = new ethers.Contract(
        EnglishAuctionAddr,
        EnglishAuctionJson.abi,
        provider!
      );

      const listingMgr = new ethers.Contract(
        ListingAddr,
        ListingJson.abi,
        provider!
      );

      const auctionItemRaw = await listingMgr.getListing(listingId);
      const tokenId = auctionItemRaw.assetId;

      const strategy =
        auctionItemRaw.strategy === process.env.REACT_APP_FIXED_ADDRESS
          ? StrategyType.FixedPrice
          : StrategyType.EnglishAuction;

      const [assetRaw, saleStrategyRaw] = await Promise.all([
        assetWrapper.getWrappedAsset(tokenId),
        strategy === StrategyType.FixedPrice
          ? fixedPrice.getAuctionItem(listingMgr.address, listingId)
          : englishAuction.getAuctionItem(listingMgr.address, listingId),
      ]);

      const underlyingTokenId = assetRaw.tokenId;

      let price = BigNumber.from(0),
        minBidStep = BigNumber.from(0),
        priceCurrencyAddress = ethers.constants.AddressZero,
        priceCurrencySymbol = "RBTC",
        hasBids = false;

      if (strategy === StrategyType.FixedPrice) {
        // Price
        price = saleStrategyRaw.currencyItems![0].buyNowPrice;
      } else {
        minBidStep = saleStrategyRaw.currencyItems![0].minBidStep;
        if (saleStrategyRaw.latestBid.bidder !== ethers.constants.AddressZero) {
          hasBids = true;
          price = (saleStrategyRaw.latestBid.bid as BigNumber).add(minBidStep);
        } else {
          price = saleStrategyRaw.currencyItems![0].reservedPrice;
        }
      }

      priceCurrencyAddress = saleStrategyRaw.currencies[0];
      if (priceCurrencyAddress === constants.AddressZero) {
        priceCurrencySymbol = RBTC;
      } else {
        const priceCurrencyErc20 = new ethers.Contract(
          priceCurrencyAddress,
          erc20MockJson.abi,
          provider!
        );
        priceCurrencySymbol = await priceCurrencyErc20.symbol();
      }

      // State
      const auctionState = auctionItemRaw.state as AuctionState;
      // Underlying contract
      const nftContractAddr = assetRaw.contractAddress;
      // End date
      const endDate = auctionItemRaw.endDate;
      // Exit fee
      const exitFee = assetRaw.exitFee;
      const exitFeeCurrencyAddress = assetRaw.exitFeeCurrency;
      let exitFeeCurrencySymbol;
      if (exitFeeCurrencyAddress === constants.AddressZero) {
        exitFeeCurrencySymbol = RBTC;
      } else {
        const exitFeeCurrencyErc20 = new ethers.Contract(
          priceCurrencyAddress,
          erc20MockJson.abi,
          provider!
        );

        exitFeeCurrencySymbol = await exitFeeCurrencyErc20.symbol();
      }

      const owner = assetRaw.owner;

      const defaultRoyalties: Royalties = {
        marketplace: BigNumber.from(0),
        sponsor: BigNumber.from(0),
        author: BigNumber.from(0),
      };

      if (owner === ethers.constants.AddressZero) {
        setState({
          price,
          priceCurrencyAddress,
          priceCurrencySymbol,
          minBidStep,
          state: auctionState,
          listingId: listingId,
          tokenURI: "",
          tokenId,
          endDate,
          nftContract: nftContractAddr,
          owner,
          exitFee,
          exitFeeCurrencyAddress,
          exitFeeCurrencySymbol,
          author: assetRaw.authorAddress,
          hasBids,
          royalties: defaultRoyalties,
          strategyType: strategy,
          underlyingTokenId,
        });
      } else {
        const nftContract = new ethers.Contract(
          nftContractAddr,
          ERC721Json.abi,
          provider!
        );

        let tokenURI = await nftContract.tokenURI(underlyingTokenId);
        tokenURI = await getImageFromSource(tokenURI);

        setState({
          price,
          priceCurrencyAddress,
          priceCurrencySymbol,
          minBidStep,
          state: auctionState,
          listingId,
          tokenURI,
          tokenId,
          endDate,
          nftContract: nftContractAddr,
          owner,
          exitFee,
          exitFeeCurrencyAddress,
          exitFeeCurrencySymbol,
          author: assetRaw.authorAddress,
          hasBids,
          royalties: defaultRoyalties,
          strategyType: strategy,
          underlyingTokenId,
        });
      }
    },
    [
      AssetWrapperAddr,
      EnglishAuctionAddr,
      FixedPriceAddr,
      ListingAddr,
      provider,
    ]
  );

  useEffect(() => {
    if (provider) {
      (async () => {
        try {
          setLoading(true);
          await fetchMetadata(listingId);
        } catch (err) {
          console.warn("could not fetch metadata for listingId", +listingId);
          console.log(err);
        }
        setLoading(false);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingId.toString(), provider]);

  return [state, loading];
};
