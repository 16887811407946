import { BigNumber, constants, ethers } from "ethers";
import create from "zustand";
import erc20MockJson from "../artifacts/ERC20Mock.json";
import ListingManager from "../artifacts/ListingManager.json";
import MarketplaceConfiguration from "../artifacts/MarketplaceConfiguration.json";
import { useConnection } from "./useConnection";

export interface ListingState {
  createListing(
    provider: any,
    nftAddress: string,
    nftTokenId: number,
    currencyAddress: string,
    amount: number,
    signer: ethers.Signer,
    strategyType: StrategyType,
    endDate: number,
    minBidStep: number
  ): Promise<void>;
  placeBid(
    listingId: BigNumber,
    price: BigNumber,
    priceCurrency: string
  ): Promise<ethers.ContractReceipt>;
  exitMarket(tokenId: BigNumber, exitFee: BigNumber): any;
  getExitFees(): any;
  deactivateListing(listingId: BigNumber): Promise<ethers.ContractReceipt>;
  activateListing(listingId: BigNumber): Promise<ethers.ContractReceipt>;
  changePrice(
    listingId: BigNumber,
    strategyType: StrategyType,
    price: BigNumber,
    currencyAddress: String,
    minBidStep: BigNumber
  ): Promise<ethers.ContractReceipt>;
  changeEndDate(
    listingId: BigNumber,
    endDate: Date
  ): Promise<ethers.ContractReceipt>;
  endSale(listingId: BigNumber): Promise<ethers.ContractReceipt>;
}

export enum StrategyType {
  FixedPrice,
  EnglishAuction,
}

const LISTING_MANAGER_ADDRESS: string = process.env.REACT_APP_LISTING_ADDRESS!;
const MKP_CONFIG_ADDRESS: string = process.env.REACT_APP_MKP_CONF_ADDRESS!;

export const useListing = create<ListingState>((set, get) => ({
  placeBid: async (
    listingId: BigNumber,
    price: BigNumber,
    priceCurrency: string
  ) => {
    const { provider, signer } = useConnection.getState();

    if (!provider || !signer) {
      throw new Error("No provider: Please connect your wallet");
    }

    const listingAddress = process.env.REACT_APP_LISTING_ADDRESS || "";

    const listing = new ethers.Contract(
      listingAddress,
      ListingManager.abi,
      provider
    );

    let placeBidTx;

    if (priceCurrency !== constants.AddressZero) {
      const erc20 = new ethers.Contract(
        priceCurrency,
        erc20MockJson.abi,
        provider!
      );
      try {
        await (
          await erc20.connect(signer).approve(listingAddress, price)
        ).wait();
      } catch (e) {
        throw new Error("Could not approve: Please check your balance");
      }

      placeBidTx = await listing
        .connect(signer)
        .placeBid(listingId, priceCurrency, price);
    } else {
      placeBidTx = await listing
        .connect(signer)
        .placeBid(listingId, priceCurrency, 0, {
          value: price,
        });
    }

    return placeBidTx.wait();
  },
  exitMarket: async (tokenId: BigNumber, exitFee: BigNumber) => {
    const { provider, signer } = useConnection.getState();

    if (!provider || !signer) {
      throw new Error("No provider: Please connect your wallet");
    }

    const listingContract = new ethers.Contract(
      LISTING_MANAGER_ADDRESS,
      ListingManager.abi,
      provider
    );
    const exitMarketTx = await listingContract
      .connect(signer)
      .exitMarketplace(tokenId, {
        value: exitFee,
      });

    return exitMarketTx.wait();
  },
  getExitFees: async () => {
    const { provider, signer } = useConnection.getState();

    if (!provider || !signer) {
      throw new Error("No provider: Please connect your wallet");
    }

    const mkpConfigurationContract = new ethers.Contract(
      MKP_CONFIG_ADDRESS,
      MarketplaceConfiguration.abi,
      provider
    );

    return await mkpConfigurationContract.getMarketplaceExitFee();
  },
  createListing: async (
    provider: any,
    nftAddress: string,
    nftTokenId: number,
    currencyAddress: string,
    amount: number,
    signer: ethers.Signer,
    strategyType = StrategyType.FixedPrice,
    endDate = 0,
    minBidStep = 1
  ) => {
    const fixedPriceAddress = process.env.REACT_APP_FIXED_ADDRESS || "";
    const englishAuctionAddress = process.env.REACT_APP_ENGLISH_ADDRESS || "";
    const listingAddress = process.env.REACT_APP_LISTING_ADDRESS || "";

    const listing = new ethers.Contract(
      listingAddress,
      ListingManager.abi,
      provider
    );

    let tx;

    if (strategyType === StrategyType.EnglishAuction) {
      tx = await listing.connect(signer).createListing(
        nftTokenId,
        nftAddress,
        endDate, // endDate
        englishAuctionAddress,
        [currencyAddress], // currencies
        [0], // buyNowPrices
        [ethers.utils.parseEther(amount.toString())], // reservedPrices
        [ethers.utils.parseEther(minBidStep.toString())], // minBidSteps
        ethers.constants.AddressZero // author address
      );
    } else {
      tx = await listing.connect(signer).createListing(
        nftTokenId,
        nftAddress,
        endDate, // endDate
        fixedPriceAddress,
        [currencyAddress], // currencies
        [ethers.utils.parseEther(amount.toString())], // buyNowPrices
        [0], // reservedPrices
        [0], // minBidSteps
        ethers.constants.AddressZero // author address
      );
    }

    return await tx.wait();
  },
  deactivateListing: async (listingId: BigNumber) => {
    const { provider, signer } = useConnection.getState();

    if (!provider || !signer) {
      throw new Error("No provider: Please connect your wallet");
    }

    const listingContract = new ethers.Contract(
      LISTING_MANAGER_ADDRESS,
      ListingManager.abi,
      provider
    );

    const deactivateListing = await listingContract
      .connect(signer)
      .deactivateListing(listingId);

    return deactivateListing.wait();
  },
  activateListing: async (listingId: BigNumber) => {
    const { provider, signer } = useConnection.getState();

    if (!provider || !signer) {
      throw new Error("No provider: Please connect your wallet");
    }

    const listingContract = new ethers.Contract(
      LISTING_MANAGER_ADDRESS,
      ListingManager.abi,
      provider
    );

    const activateListing = await listingContract
      .connect(signer)
      .activateListing(listingId);

    return activateListing.wait();
  },
  changePrice: async (
    listingId: BigNumber,
    strategyType: StrategyType,
    price: BigNumber,
    currencyAddress: string,
    minBidStep: BigNumber
  ) => {
    const { provider, signer } = useConnection.getState();

    if (!provider || !signer) {
      throw new Error("No provider: Please connect your wallet");
    }

    const listingContract = new ethers.Contract(
      LISTING_MANAGER_ADDRESS,
      ListingManager.abi,
      provider
    );
    let changePrice;

    if (strategyType === StrategyType.EnglishAuction) {
      changePrice = await listingContract
        .connect(signer)
        .changePrice(listingId, currencyAddress, 0, price, minBidStep);
    } else {
      changePrice = await listingContract
        .connect(signer)
        .changePrice(listingId, currencyAddress, price, 0, 0);
    }

    return changePrice.wait();
  },
  changeEndDate: async (listingId: BigNumber, endDate: Date) => {
    const { provider, signer } = useConnection.getState();

    let endDateTimestamp = Math.floor(endDate.getTime() / 1000);

    if (!provider || !signer) {
      throw new Error("No provider: Please connect your wallet");
    }

    const listingContract = new ethers.Contract(
      LISTING_MANAGER_ADDRESS,
      ListingManager.abi,
      provider
    );

    const changeEndDate = await listingContract
      .connect(signer)
      .changeEndDate(listingId, endDateTimestamp);

    return changeEndDate.wait();
  },
  endSale: async (listingId: BigNumber) => {
    const { provider, signer } = useConnection.getState();

    if (!provider || !signer) {
      throw new Error("No provider: Please connect your wallet");
    }

    const listingContract = new ethers.Contract(
      LISTING_MANAGER_ADDRESS,
      ListingManager.abi,
      provider
    );

    const endSale = await listingContract.connect(signer).endSale(listingId);

    return endSale.wait();
  },
}));
