import { Alert, Divider, Grid } from "@mui/material";
import { GlobalSettings } from "../components/settings/GlobalSettings";
import { MarketplaceSettings } from "../components/settings/MarketplaceSettings";
import { Title } from "../components/settings/styles";
// import { WhitelistedSponsors } from "../components/settings/WhitelistedSponsors";
import { LoadingBackdrop } from "../components/shared/LoadingBackdrop";
import { useSettings } from "../hooks/useSettings";

export const Settings = () => {
  const [settings, isLoading] = useSettings();

  return (
    <Grid container spacing={2} sx={{ marginTop: 5 }}>
      <Grid item md={12} sx={{ marginBottom: "20px" }}>
        <Alert severity="warning">
          <>
            Only the owner of the marketplace is allowed to change the settings
          </>
        </Alert>
      </Grid>
      <Grid item md={12}>
        <LoadingBackdrop open={isLoading} />
        <Title variant="h6">Settings</Title>
        <Divider />
        <GlobalSettings settings={settings} />
        {/* <WhitelistedSponsors /> */}
        <MarketplaceSettings
          marketplaceFeesPayee={settings.marketplaceFeesPayee}
        />
      </Grid>
    </Grid>
  );
};
