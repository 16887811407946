import { Box, Container } from "@mui/system";
import { FooterText } from "./Footer.styles";

export const Footer = () => {
  return (
    <Container>
      <Box style={{ width: "100%", marginTop: "30px", paddingBottom: "50px" }}>
        <FooterText>RIF Marketplace Demo {new Date().getFullYear()}</FooterText>
      </Box>
    </Container>
  );
};
