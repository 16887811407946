import { createGlobalStyle, ThemeProvider } from "styled-components";
import { CssBaseline } from "@mui/material";
import { createTheme } from "@mui/system";

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Rubik', sans-serif;
  }

  * {
    font-family: 'Rubik' !important;
  }
`;

const marketplaceTheme = createTheme({
  typography: {
    fontFamily: "Rubik",
  },
});

type Props = {
  children: JSX.Element;
};

export const MarketplaceTheme: React.FC<Props> = ({ children }) => {
  return (
    <ThemeProvider theme={marketplaceTheme}>
      <CssBaseline />
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
};
