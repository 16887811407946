import { ArrowForward } from "@mui/icons-material";
import { Container, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { BrowseListings } from "../components/listings";

export const Home = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Paper
        sx={[
          {
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            marginTop: "20px",
            padding: "20px",
            backgroundSize: "1000px",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "-300% -350px",
            backgroundImage:
              "url(https://gateway.pinata.cloud/ipfs/QmQWVAuTJyqjcqaW7UGw7DsoU2N9xBRVLFATzFAGxYHW5N)",
            cursor: "pointer",
          },
          {
            "&:hover svg": {
              marginLeft: "15px",
            },
          },
        ]}
        onClick={() => {
          navigate("/minter");
        }}
      >
        <p
          style={{
            color: "black",
            display: "inline-block",
          }}
        >
          Mint your own NFT here
        </p>
        <ArrowForward
          sx={{
            width: "19px",
            marginLeft: "10px",
            transition: "margin 0.3s ease",
          }}
        />
      </Paper>
      <BrowseListings />
    </Container>
  );
};
