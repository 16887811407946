export const formatAddress = (address: string, length: number = 15): string => {
  if (length > address.length - 3) return address;

  const lengthOnEachSide = Math.floor(length / 2);

  return (
    address.slice(0, lengthOnEachSide) +
    "..." +
    address.slice(address.length - lengthOnEachSide)
  );
};

export const padTo2Digits = (num: number) => {
  return num.toString().padStart(2, "0");
};

export const formatDate = (date: Date) => {
  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-") +
    " " +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds()),
    ].join(":")
  );
};

export const getImageFromSource = async (imgSrc: string): Promise<string> => {
  let res = await fetch(imgSrc);
  let contentType = res.headers.get("content-type");
  let isJSON = contentType && contentType.indexOf("application/json") !== -1;

  if (isJSON) {
    let jsonRes = await res.json();
    return jsonRes.image;
  }

  return imgSrc;
};

export const RBTC = "tRBTC";
