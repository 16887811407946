import { AuctionState, Royalties } from "../../hooks/useListingMetadata";
import { ethers, BigNumber } from "ethers";
import {
  Card,
  CardMedia,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  tableCellClasses,
  Grid,
  Typography,
  Divider,
} from "@mui/material";
import { formatAddress } from "../../utils";

interface ListingDataProps {
  listingId: BigNumber;
  tokenURI: string;
  author: string;
  owner: string;
  tokenId: BigNumber;
  nftContract: string;
  royalties: Royalties;
  exitFee: BigNumber;
  exitFeeCurrencySymbol: string;
  state: AuctionState;
}

export const ListingData = ({
  listingId,
  tokenURI,
  author,
  owner,
  tokenId,
  nftContract,
  royalties,
  exitFee,
  exitFeeCurrencySymbol,
  state,
}: ListingDataProps) => {
  const toNumber = (bn: BigNumber) => {
    return BigNumber.from(bn).toNumber();
  };

  return (
    <Grid container>
      <Grid item md={12}>
        <Card elevation={1}>
          {tokenURI && (
            <CardMedia
              component="img"
              alt="Listing image"
              height="300"
              sx={{
                objectFit: "contain",
              }}
              image={tokenURI}
            />
          )}
        </Card>
      </Grid>
      <Grid
        item
        md={12}
        sx={{
          marginTop: 3,
        }}
      >
        <Typography
          sx={{
            marginLeft: 2,
          }}
          fontWeight="bold"
        >
          Details
        </Typography>
        <Divider />
      </Grid>
      <Grid item md={12}>
        <TableContainer>
          <Table
            aria-label="spanning table"
            size="small"
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none",
                fontSize: 12,
                color: "#636363",
              },
            }}
          >
            <TableBody>
              <TableRow>
                <TableCell colSpan={4}>Listing ID</TableCell>
                <TableCell colSpan={7} align="right">
                  {toNumber(listingId)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3}>Author</TableCell>
                <TableCell colSpan={3} align="right">
                  {formatAddress(author)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3}>Owner</TableCell>
                <TableCell colSpan={3} align="right">
                  {formatAddress(owner)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3}>Network</TableCell>
                <TableCell colSpan={3} align="right">
                  RSK
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3}>NFT Contract Address</TableCell>
                <TableCell colSpan={3} align="right">
                  {formatAddress(nftContract)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3}>Token ID</TableCell>
                <TableCell colSpan={3} align="right">
                  {toNumber(tokenId)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3}>Marketplace Royalty fee</TableCell>
                <TableCell colSpan={3} align="right">
                  {royalties?.marketplace
                    ? toNumber(royalties?.marketplace)
                    : 0}{" "}
                  %
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4}>Sponsor Royalty fee</TableCell>
                <TableCell colSpan={7} align="right">
                  {royalties?.sponsor ? toNumber(royalties?.sponsor) : 0} %
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4}>Author Royalty fee</TableCell>
                <TableCell colSpan={7} align="right">
                  {royalties?.author ? toNumber(royalties?.author) : 0} %
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4}>Exit fee</TableCell>
                <TableCell colSpan={7} align="right">
                  {ethers.utils.formatEther(exitFee)} {exitFeeCurrencySymbol}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4}>State</TableCell>
                <TableCell colSpan={7} align="right">
                  {AuctionState[state]}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};
