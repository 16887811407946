import { Settings } from "@mui/icons-material";
import { AppBar, Button, Chip, Container, Toolbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ethers } from "ethers";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import rifLogo from "../../assets/riflogo.svg";
import { useAssetWrapper } from "../../hooks/useAssetWrapper";
import { useConnection } from "../../store/useConnection";
import { formatAddress } from "../../utils";
import { LogoImage } from "./Header.styles";

export const Header = () => {
  const connection = useConnection();
  const navigate = useNavigate();
  const [assetWrapper] = useAssetWrapper();

  useEffect(() => {
    connection.checkConnection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppBar position="static" elevation={0}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <LogoImage src={rifLogo} alt="RIF Marketplace" style={{ cursor: "pointer" }} onClick={() => navigate("/")} />
          <Typography
            style={{
              marginTop: "7px",
              marginLeft: "10px"
            }}
            fontFamily="Rubik"
            fontWeight="bold"
          >
            | Marketplace
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "right"
            }}
          >
            {!connection.isConnected && (
              <Button
                variant="outlined"
                size="small"
                sx={[
                  {
                    color: "white",
                    borderColor: "white",
                    textTransform: "none",
                    fontSize: "0.8em",
                    borderRadius: "100px"
                  },
                  {
                    "&:hover": {
                      color: "white",
                      borderColor: "white",
                      opacity: "0.8"
                    }
                  }
                ]}
                onClick={() => connection.connect()}
              >
                Connect wallet
              </Button>
            )}
            {connection.isConnected && (
              <>
                <Chip label={`Connected as: ${formatAddress(connection.address, 15)}`} sx={{ color: "white" }} />

                <Button
                  variant="outlined"
                  size="small"
                  sx={[
                    {
                      color: "white",
                      borderColor: "white",
                      textTransform: "none",
                      fontSize: "0.8em",
                      borderRadius: "100px",
                      marginLeft: "10px"
                    },
                    {
                      "&:hover": {
                        color: "white",
                        borderColor: "white",
                        opacity: "0.8"
                      }
                    }
                  ]}
                  onClick={() => navigate("/create")}
                >
                  Create Listing +
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  title="Click to withdraw"
                  sx={[
                    {
                      color: "white",
                      borderColor: "white",
                      textTransform: "none",
                      fontSize: "0.8em",
                      borderRadius: "100px",
                      marginLeft: "10px"
                    },
                    {
                      "&:hover": {
                        color: "white",
                        borderColor: "white",
                        opacity: "0.8"
                      }
                    }
                  ]}
                  onClick={() => {
                    if (assetWrapper.balance.gt(0)) {
                      assetWrapper.withdraw(assetWrapper.balance);
                    }
                  }}
                >
                  Balance: {ethers.utils.formatEther(assetWrapper.balance)} tRBTC
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  sx={[
                    {
                      color: "white",
                      borderColor: "white",
                      textTransform: "none",
                      fontSize: "0.8em",
                      borderRadius: "100px",
                      marginLeft: "10px",
                      padding: "0px !important",
                      minWidth: "40px"
                    },
                    {
                      "&:hover": {
                        color: "white",
                        borderColor: "white",
                        opacity: "0.8"
                      }
                    },
                    {
                      "& .mktpc-startIcon": {
                        margin: "0px"
                      }
                    }
                  ]}
                  onClick={() => navigate("/settings")}
                  startIcon={<Settings />}
                ></Button>
              </>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
