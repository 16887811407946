import { Container } from "@mui/system";
import { BigNumber } from "ethers";
import { formatDate } from "../../utils";

interface ListingEndDateProps {
  endDate: BigNumber;
}

export const ListingEndDate = ({ endDate }: ListingEndDateProps) => {
  const endDateObj = new Date(endDate.toNumber() * 1000);

  return (
    <Container
      sx={{
        paddingLeft: "15px !important",
      }}
    >
      Ending on: {formatDate(endDateObj)}
    </Container>
  );
};
