import { Button, TextField } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { BigNumber } from "ethers";
import { useRef, useState } from "react";
import { useListing } from "../../../store/useListing";
import { TriggerModal } from "../../Modal";
import { TriggerSnackbar } from "../../Snackbar";

interface ChangeEndDateButtonProps {
  listingId: BigNumber;
  currentEndDate: BigNumber;
}

export const ChangeEndDateButton = ({
  listingId,
  currentEndDate,
}: ChangeEndDateButtonProps) => {
  const endDate = currentEndDate.eq(0)
    ? new Date()
    : new Date(currentEndDate.toNumber());
  const { changeEndDate } = useListing();
  const dateInputRef = useRef<HTMLInputElement>();

  const ModalContent = () => {
    const [value, setValue] = useState<Date | null>(endDate);

    const changeValue = (value: Date | null) => {
      setValue(value);
    };

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          label="Expiration time"
          autoFocus
          value={value}
          onChange={changeValue}
          renderInput={(params) => (
            <TextField
              {...params}
              inputRef={(el) => {
                if (params.inputRef) {
                  if (typeof params.inputRef === "function")
                    params.inputRef(el);
                }

                dateInputRef.current = el;
              }}
            />
          )}
        />
      </LocalizationProvider>
    );
  };

  const onConfirmationHandler = async () => {
    try {
      const date = new Date(dateInputRef.current?.value || "") || endDate;
      await changeEndDate(listingId, date);
      TriggerSnackbar("End date updated successfully", "success");
    } catch (error) {
      const err = error as Error;
      console.log(err);
      TriggerSnackbar(err.message, "error");
    }
    return true;
  };

  const onClickHandler = () => {
    TriggerModal("Change end date", <ModalContent />, onConfirmationHandler);
  };

  return (
    <Button onClick={onClickHandler} size="small" variant="outlined">
      Change end date
    </Button>
  );
};
