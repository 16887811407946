import { Button } from '@mui/material';
import { BigNumber } from 'ethers';
import { AuctionState } from '../../../hooks/useListingMetadata';
import { useListing } from '../../../store/useListing';
import { TriggerModal } from '../../Modal';
import { TriggerSnackbar } from '../../Snackbar';

interface ActivationButtonProps {
  listingId: BigNumber;
  state: AuctionState;
}

export const ActivationButton = ({ listingId, state }: ActivationButtonProps) => {
  const { activateListing, deactivateListing } = useListing();
  const isActivation = state === AuctionState.Inactive;

  const ModalContent = () => {
    return (
      <>
        Are you sure you want to <b>{isActivation ? 'activate' : 'deactivate'}</b> the listing #{listingId.toNumber()}?
      </>
    );
  };

  const onConfirmation = async () => {
    try {
      if (isActivation) {
        await activateListing(listingId);
      } else {
        await deactivateListing(listingId);
      }

      let successMessage = isActivation ? 'Successfully activated listing' : 'Successfully deactivated listing';

      TriggerSnackbar(successMessage, 'success');
    } catch (err) {
      TriggerSnackbar((err as Error).message, 'error');
    }
    return true;
  };

  const title = isActivation ? 'Activate listing' : 'Deactivate listing';

  const onClickHandler = () => {
    TriggerModal(title, <ModalContent />, onConfirmation);
  };

  return (
    <Button onClick={onClickHandler} size="small" variant="outlined">
      {isActivation ? 'Activate' : 'Deactivate'}
    </Button>
  );
};
